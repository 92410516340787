import React, { Component } from 'react';
import { Container, Row, Alert, Input, Button, ButtonGroup, UncontrolledPopover, PopoverBody } from 'reactstrap';
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faCopy } from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as Logo } from '../logo.svg';


export class Home extends Component {
  state = {
    loading: false,
    error: '',
    result: '',
    input: '',
    emtpyerr: false,
  }

  render() {

    let buttons = []

    buttons.push(<Button color='primary' onClick={e => {
      if (this.state.loading) {
        return
      }

      if (this.state.result) {
        this.setState({
          loading: false,
          input: '',
          result: '',
          error: '',
        })
        return
      }

      if (!this.state.input) {
        this.setState({
          emtpyerr: true
        })
        setTimeout(() => {
          this.setState({
            emtpyerr: false
          })
        }, 200);
        return
      }

      this.setState({
        loading: true,
        error: '',
      })

      fetch("/api/create", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ url: this.state.input }),
      })
        .then(response => response.json())
        .then(data => {
          let location = window.location
          let prefix = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");

          if (data.error) {
            this.setState({
              error: data.error,
            })

          } else if (data.key) {
            this.setState({
              result: prefix + "/" + data.key,
              error: null,
            })
          }
        })
        .catch(() => {
          this.setState({
            error: "unknown error",
          })
        })
        .finally(() => {
          this.setState({
            loading: false,
          })
        })

    }}>{this.state.loading ? "..." : (this.state.result ? "再淘一个" : "tao.bb")}</Button>
    )

    if (this.state.result) {
      buttons.push(<CopyToClipboard text={this.state.result}><Button color='primary' ><FontAwesomeIcon icon={faCopy} /></Button></CopyToClipboard>)
      buttons.push(<Button color='primary' id="qrcode" ><FontAwesomeIcon icon={faQrcode} /></Button>)
    }

    return (
      <Container>
        <Alert color="danger" transition="all 0.5s"
          style={
            {
              opacity: this.state.error ? 100 : 0,
              textAlign: 'center',
            }
          }
        >
          &nbsp;{this.state.error}&nbsp;
        </Alert >
        <Row style={{ marginTop: 80 }}>
            <svg height='88' viewBox="0 0 370 100">
              <Logo />
            </svg>
        </Row>

        <Row style={{ marginTop: 50 }}>
          <Input className='input-taobb'
            value={this.state.result ? this.state.result : this.state.input}
            readOnly={this.state.result ? true : false}
            autoFocus={true}
            autoComplete={"off"}
            placeholder="输入你想变短的网址如 http://www.taobao.com"
            style={
              this.state.result ? { width: '33%' } : {
                width: '100%',
                animation: this.state.emtpyerr ? 'shake .5s' : '',
                position: 'relative',
              }
            }
            onTransitionEnd={e => {
              if (this.state.result) {
                e.target.select()
              }

            }}
            onChange={e => {
              if (!this.state.result) {
                this.setState({
                  'input': e.target.value
                })
              }
            }}

          />
        </Row>

        <Row style={{ marginTop: 50 }}>

          <ButtonGroup style={{ margin: 'auto', width: 250 }}>
            {buttons}
          </ButtonGroup>

        </Row>
        {this.state.result ?
          <UncontrolledPopover
            hideArrow={false}
            placement="bottom"
            target="qrcode">
            <PopoverBody>
              <QRCode value={this.state.result} size={128} />
            </PopoverBody>
          </UncontrolledPopover>
          : ''}
      </Container>
    );
  }
}
