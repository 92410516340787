import React, { Component } from 'react';
import { Home } from './components/Home';

import './custom.css'

export default class App extends Component {
  render() {
    return (
      <div>
        <Home />
        <div style={{ marginTop: 100 }}>
          <div className="container text-center">
            <p className="muted"> <a href="mailto:farmer1992@gmail.com">联系站长</a></p>
          </div>
        </div>
      </div>
    );
  }
}
